import { useEffect, useState } from 'react';
import axios from 'axios';
import { gamesData } from './GameData';
import { useSharedCurrencyState } from '../layouts/Core/HeroBanner';

export interface IGame {
	name: string;
	startingAt?: string;
	keywords: string;
	extraClassName?: string;
	boxImage?: string;
	sortTags: string;
	key?: string;
	description?: string;
	comingsoon?: boolean;
	hook?: string;
	logoImage?: string;
	stock?: boolean;
	purchaseOn?: string;
	perSlot?: string;
	slotLimit?: string;
	gbLimit?: string;
	locations?: number;
	everylocation?: string[];
	backgroundImage?: string;
	youtubeLink?: string;
	orderLink?: string;
	storeLink?: string;
	tabs?: string[];
	latest?: boolean;
	earlyaccess?: boolean;
	trending?: boolean;
	trendingIMG?: string;
	missing?: boolean;
	customFAQ1q?: string;
	customFAQ1?: string;
	customFAQ2q?: string;
	customFAQ2?: string;
	customFAQ3q?: string;
	customFAQ3?: string;
	customFAQ4q?: string;
	customFAQ4?: string;
	customFAQ5q?: string;
	customFAQ5?: string;
};

interface GameAPIMemoryOption {
	name: string;
	price: string;
}

interface GameAPICPUCore {
	name: string;
	price: string;
}

interface GameAPIResponse {
	Name: string;
	Price: string;
	ID: number;
	Stock: boolean;
	Description: string;
	SlotsMin: number;
	SlotsMax: number;
	Currency: string;
	SlotsPrice: string;
	TotalLocations: number;
	AllLocations: string[];
	MemoryOptions: GameAPIMemoryOption[];
	CPUCores: GameAPICPUCore[];
}

export function GameLookup(key, gamesList) {
    // Return a blank "game" to keep things well defined.
    let validGame = gamesList.find(x => x.key === key );
    return validGame === undefined ? {
        name: '',
        keywords: '',
        boxImage: '',
        extraClassName: '',
        sortTags: '',
        key: undefined,
        description: '',
        hook: '',
        logoImage: '',
        purchaseOn: '',
        perSlot: '',
        slotLimit: '',
		gbLimit: '',
        backgroundImage: '',
		youtubeLink: '',
		orderLink: '',
        locations: 0,
    } : validGame;
}

const extractNumber = (str: string): number | null => {
    const match = str.match(/(\d+(\.\d+)?)/);
    return match ? parseFloat(match[0]) : null;
}

export function useGamesList() {
    const [games, setGames] = useState<IGame[]>(gamesData);
    const { currency, setCurrency, currencySign, currencyId  } = useSharedCurrencyState();

    useEffect(() => {
        axios.get(`https://billing.streamline-servers.com/store/game-servers?json=1&currency=${currencyId}`)
            .then((response) => {
                const apiGames = response.data.map((game: GameAPIResponse) => {

                    // Extract numerical values from MemoryOptions names
                    const memoryValues = game.MemoryOptions
                      .map(option => extractNumber(option.name))
                      .filter((value): value is number => value !== null);

                    // Compute minimum and maximum memory values
                    const minMemory = memoryValues.length > 0 ? Math.min(...memoryValues) : 0;
                    const maxMemory = memoryValues.length > 0 ? Math.max(...memoryValues) : 0;

                    return {
						orderLink: 'https://billing.streamline-servers.com/cart.php?a=add&pid=' +  game.ID + '&currency=' + currencyId,
                        name: game.Name,
                        startingAt: game.Price,
                        perSlot: game.SlotsPrice,
                        slotLimit: `${game.SlotsMin}-${game.SlotsMax}`,
                        gbLimit: `${minMemory}-${maxMemory}`,
                        locations: '22', //need to fix API, originally locations: game.TotalLocations,
						everylocation: 'Sydney Melbourne Brisbane Adelaide Perth Auckland Singapore Hong Kong Tokyo Atlanta São Paulo Montreal Chicago Seattle Dallas Los Angeles Miami New York Amsterdam Paris Frankfurt London', //need to fix API, originally everylocation: game.AllLocations
							//.map(location => location.replace(/\[.*?\]\s*/, '')),  // Remove the country code and trim the extra spaces
						stock: game.Stock,
						comingsoon: game.Description.toLowerCase().includes('coming soon'),
                    };
                });

				setGames((currentGames) => {
					return currentGames
						.map((game) => {
							const apiGame = apiGames.find((g) => g.name.toLowerCase() === game.name.toLowerCase());
							if (apiGame) {
								return {
									...game,
									...apiGame,
									name: game.name, // restore the original game name
								};
							}
							return { 
                                ...game,
                                missing: true // Set `missing` to true if the game doesn't exist in the API data
                            };
						})
						.filter((game) => game !== null); // Filter out the null values
				});
			})
			.catch((error) => {
				console.error(error);
			});
		}, [currency, currencySign, currencyId]);
    return games;
}